import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { ListUsers, User } from "../staff/listUsers";
import { AdministrationUserApi } from "../lib/apiActions";
import { useAppSelector } from "../lib/hook";
import { authSelector } from "../lib/ducks/authDuck";
import { Navigate } from "react-router-dom";

interface Props {

}

const StaffView: React.FC<Props> = (props) => {
    // const [open, setOpen] = useState(false);
    const token = useAppSelector(authSelector).token;
    const user = useAppSelector(authSelector).user;
    const [users, setUsers] = useState<User[] | null>(null);

    useEffect(() => {
        if (!user?.is_staff) {
            return;
        }

        const fetchUsers = async () => {
            try {
                const response = await AdministrationUserApi.get({
                    headers: {
                        Authorization: `Token ${token}`, 
                    },
                });
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [user]);

    if (!user?.is_staff) {
        return <Navigate to="/" />;
    }

    return (
        <Box sx={{
            width: "70vw",
        }}>
            <ListUsers 
                users={users}
                // open={open}
            />
        </Box>
    );
}

export default StaffView;