import { EditNote } from "@mui/icons-material";
import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled, Toolbar, Tooltip, Typography } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import React, { useEffect, useState } from "react";
import StyledAvatar from "./avatar";


import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpIcon from '@mui/icons-material/Help';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useAppDispatch, useAppSelector } from "../lib/hook";
import { chatSelector, setSelectedPipe } from "../lib/ducks/chatDuck";
import { authSelector, requestLogoutAllAndLogout, setDarkTheme, Role } from "../lib/ducks/authDuck";
import { useNavigate } from "react-router-dom";
import useConfirm from "./dialogs/ConfirmDialog";
import { getPipeColor } from "../utils/pipeColor";

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

import CheckIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from 'react-router-dom';
import ForumIcon from '@mui/icons-material/Forum';
import CPlusIcon from '../assets/images/cplus.svg'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonIcon from '@mui/icons-material/Person';

const allPipes =
    [
        ["Työ", "Perintö", "Tietosuoja"],
        ["Kiinteistö", "Vakuutus", "Insolvenssi", "Sijoitus & pääoma"],
        ["Julkiset hankinnat", "Vero",],
        ["IPR", "Perhe"],
        ["Ympäristö", "Rikos"]
    ]

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - 80px)`,
    ...(open && {
        width: `calc(100% - ${drawerwidth}px)`,
        marginLeft: `${drawerwidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    drawerwidth: number
}

interface ComponentProps {
    drawerWidth: number,
    leftDrawerOpen: boolean | undefined,
    handleNewChat: any,
    handleDrawerOpen: any,
    handleDrawerClose: any,
    handleFeedback: any
}

interface RoleIconProps {
    role: string,
}

const RoleIcon: React.FC<RoleIconProps> = ({ role }) => {

    if (role === "default")
        return (<QuestionAnswerIcon
            fontSize={"small"}
            sx={{ color: getPipeColor(role) }}
        />)
    if (role === "Työ")
        return (<QuestionAnswerIcon
            fontSize={"small"}
            sx={{ color: "#D4714C" }}
        />)

    if (role === "Rikos")
        return (<QuestionAnswerIcon
            fontSize={"small"}
        />)

    if (role === "Perintö")
        return (<QuestionAnswerIcon
            sx={{ color: "#7B4397" }}
            fontSize={"small"}
        />)

    if (role === "Tietosuoja")
        return (<QuestionAnswerIcon
            sx={{ color: "#48C9B0" }}
            fontSize={"small"}
        />)

    if (role === "Perintö+")
        return (<QuestionAnswerIcon
            sx={{ color: "#a402f5" }}
            fontSize={"small"}
        />)

    if (role === "Perhe")
        return (<QuestionAnswerIcon
            fontSize={"small"}
        />)


    return (<QuestionAnswerIcon
        fontSize={"small"}
    />)
}

interface CategorySelectionProps {
    selectedPipe: string,
    setSelectedPipe: any,
    roles: Role[] | undefined
}

const CategorySelection: React.FC<CategorySelectionProps> = ({ selectedPipe, setSelectedPipe, roles }) => {
    //console.log("rendered")

    const flatRoleList = allPipes.flat()

    const extraRoles = roles?.filter(r => r.name != "default" && !flatRoleList.some(s => s === r.name));
    return (
        <React.Fragment key={"categorySelectionFragment"}>
            <MenuItem
                onClick={() => setSelectedPipe("default")}
                sx={{ paddingRight: 0, paddingY: 1 }}
                key={"defaultmenu"}
            >
                <ListItemIcon >
                    <RoleIcon role={"default"} />
                </ListItemIcon>
                <ListItemText >Generalisti</ListItemText>
                {selectedPipe === "default" &&
                    < ListItemIcon sx={{ ml: 'auto' }}>
                        <CheckIcon color="primary" />
                    </ListItemIcon>
                }
            </MenuItem>
            {
                allPipes.map((roleCategory, categoryIndex) => {

                    return (
                        <React.Fragment key={"categoryindex" + categoryIndex}>
                            <Divider />
                            {roleCategory.map((role, index) => {
                                const isAvailable = roles && roles.some(r => r.name === role);
                                return (
                                    <MenuItem
                                        key={"category" + categoryIndex + "|" + index}
                                        sx={{ paddingRight: 1, paddingY: 1 }}
                                        disabled={!isAvailable}
                                        onClick={() => setSelectedPipe(role)}
                                    >
                                        <ListItemIcon >
                                            <RoleIcon role={role} />
                                        </ListItemIcon>
                                        <ListItemText >{role} {!isAvailable && "(tulossa)"}</ListItemText>
                                        {selectedPipe === role &&
                                            < ListItemIcon sx={{ ml: 'auto' }}
                                                key={"categoryicon" + categoryIndex + "|" + index}
                                            >
                                                <CheckIcon color="primary" />
                                            </ListItemIcon>
                                        }
                                    </MenuItem>

                                );
                            })}

                        </React.Fragment>
                    );
                })
            }

            {extraRoles && extraRoles.length > 0 &&
                <Divider />
            }

            {
                extraRoles?.map((role, roleIndex) => {
                    return (
                        <MenuItem
                            key={"category+" + roleIndex + "|" + role.name}
                            sx={{ paddingRight: 1, paddingY: 1 }}
                            onClick={() => setSelectedPipe(role.name)}
                        >
                            <ListItemIcon >
                                <RoleIcon role={role.name} />
                            </ListItemIcon>
                            <ListItemText >{role.name}</ListItemText>
                            {selectedPipe === role.name &&
                                < ListItemIcon sx={{ ml: 'auto' }}
                                    key={"categoryicon+" + roleIndex + "|" + role.name}
                                >
                                    <CheckIcon color="primary" />
                                </ListItemIcon>
                            }
                        </MenuItem>
                    )
                })
            }


        </React.Fragment>
    )
};

export const TopBar: React.FC<ComponentProps> = ({ drawerWidth, leftDrawerOpen, handleNewChat, handleDrawerOpen, handleDrawerClose, handleFeedback }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const [getConfirmation, ConfirmationDialog] = useConfirm();
    const query_quota = useAppSelector(chatSelector).query_quota
    const user = useAppSelector(authSelector).user
    const roles = useAppSelector(authSelector).user?.roles
    const selectedPipe = useAppSelector(chatSelector).selectedPipe
    const darkTheme = useAppSelector(authSelector).darkTheme
    const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null);
    const [anchorElPipe, setAnchorElPipe] = React.useState<null | HTMLElement>(null);
    const [currentHeader, setCurrentHeader] = useState<null | any>(null);


    const pipeMenuOpen = Boolean(anchorElPipe);
    const profileMenuOpen = Boolean(anchorElProfile);
    const handlePipeMenuClose = () => {
        setAnchorElPipe(null);
    };
    const handleProfileMenuClose = () => {
        setAnchorElProfile(null);
    };

    const onLogout = async () => {
        const response = await getConfirmation({ title: 'Haluatko varmasti kirjautua ulos?', text: '', okText: 'Kyllä', cancelText: 'Peruuta', buttonColorWarning: false })
        if (response) {
            dispatch(requestLogoutAllAndLogout())
        }
    }

    useEffect(() => {
        console.log("location", location)
        switch (location.pathname) {
            case "/search":
                setCurrentHeader({ header: "Listaustyökalu", icon: <FormatListBulletedIcon sx={{ ml: 1, mr: 2, }} /> });
                break;
            case "/profile":
                setCurrentHeader({ header: "Käyttäjätili", icon: <PersonIcon sx={{ ml: 1, mr: 2, }} /> });
                break;
            case "/info":
                setCurrentHeader({ header: "Ohje", icon: <HelpIcon sx={{ ml: 1, mr: 2, }} /> });
                break;
            case "/settings":
                setCurrentHeader({ header: "Asetukset", icon: <FormatListBulletedIcon sx={{ ml: 1, mr: 2, }} /> });
                break;
            default:
                setCurrentHeader({
                    header: user?.type === "lawyer" ? "Codex+" : "Codex+ taskulakikirja", 
                    icon: <QuestionAnswerIcon sx={{ ml: 1, mr: 2 }} />, 
                    showPipe: true 
                });
        }
    }, [location])



    return (
        <>
            <ConfirmationDialog />
            <AppBar drawerwidth={drawerWidth} position="fixed" open={leftDrawerOpen} sx={{ height: '64px', borderBottom: 1, borderColor: '#E6E6E9' }} elevation={0}>
                <Toolbar>

                    <IconButton
                        color="inherit"
                        aria-label="new chat"
                        onClick={handleNewChat}
                        edge="start"
                        sx={{ mr: 2, ...(leftDrawerOpen && { display: 'none' }) }}
                    >
                        <EditNote />
                    </IconButton>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            mr: 2,
                            ...(leftDrawerOpen && { display: 'none' })
                        }}
                    >
                        <ChevronRightIcon />
                    </IconButton>

                    {currentHeader?.icon}
                    <Typography variant="h6" fontWeight={600} fontSize={'23px'} noWrap component="div"
                        sx={{ mr: 4 }}
                    >
                        {currentHeader?.header ?? "Codex+"}
                    </Typography>
                    { user?.type == "lawyer" &&
                        currentHeader?.showPipe &&
                        <>
                            <RoleIcon role={selectedPipe} />
                            <Typography fontWeight={500} fontSize={'18px'} noWrap component="div"
                                sx={{ mr: 2, ml: 1 }}
                            >
                                {selectedPipe != "default" ? selectedPipe : "Generalisti"}
                            </Typography>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={(event) => { setAnchorElPipe(event.currentTarget); }}
                                edge="start"
                                sx={{
                                    //flexGrow: 1 
                                    //marginRight: 'auto',
                                    //ml: 2,
                                    transform: 'rotate(270deg)'
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </>
                    }

                    <Tooltip title={"Jäljellä olevat kysymykset"} sx={{ marginLeft: 'auto' }}>
                        <MonetizationOnIcon />
                    </Tooltip>
                    <Tooltip title={"Jäljellä olevat kysymykset"}>
                        <Typography variant="h6" fontWeight={600} fontSize={'18px'} noWrap component="div" paddingLeft={'8px'} >
                            {query_quota}
                        </Typography>
                    </Tooltip>

                    <IconButton sx={{ marginLeft: '32px' }} onClick={(event) => { setAnchorElProfile(event.currentTarget); }}>
                        <StyledAvatar avatarName={user?.avatar_name} size={42} sx={{}} />
                    </IconButton>
                </Toolbar>
            </AppBar >
            <Toolbar sx={{ borderBottom: 1, height: '64px' }} />

            <Menu
                anchorEl={anchorElProfile}
                open={profileMenuOpen}
                onClose={handleProfileMenuClose}
            >
                <MenuItem onClick={() => { navigate('/profile'); handleDrawerClose(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <StyledAvatar avatarName={user?.avatar_name} size={40} sx={{}} />
                    </ListItemIcon>
                    <Box
                        //display={'flex'} flex={1} alignItems={'center'} flexDirection={'column'}
                        marginLeft={2}
                    >
                        <Box>
                            <Typography fontSize={14} >{user?.first_name + ' ' + user?.last_name}</Typography>
                            <Typography fontSize={11}>{user?.email}</Typography>
                        </Box>
                    </Box>
                </MenuItem>
                {/*
                <MenuItem onClick={() => { handleProfileMenuClose(); dispatch(setDarkTheme(!darkTheme)) }}>
                    <ListItemIcon sx={{ paddingLeft: '4px' }}>
                        {darkTheme ? <DarkModeIcon /> : <LightModeIcon />}
                    </ListItemIcon>
                    <ListItemText>Ulkoasu: {darkTheme ? 'Tumma teema' : 'Vaalea teema'}</ListItemText>
                </MenuItem>
                
                <Divider />
                <MenuItem onClick={() => { navigate('/settings'); handleDrawerClose(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText  >Asetukset</ListItemText>
                </MenuItem>
                */}
                <MenuItem onClick={() => { navigate('/info'); handleDrawerClose(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText >Ohje</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { handleFeedback(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <FeedbackIcon />
                    </ListItemIcon>
                    <ListItemText >Lähetä palautetta</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { onLogout(); handleProfileMenuClose() }}>
                    <ListItemIcon >
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText >Kirjaudu ulos</ListItemText>
                </MenuItem>
            </Menu>


            <Menu
                anchorEl={anchorElPipe}
                open={pipeMenuOpen}
                onClose={handlePipeMenuClose}
                PaperProps={{
                    style: {
                        //width: 220,
                    },
                }}
            >
                <CategorySelection
                    setSelectedPipe={(role: any) => { handlePipeMenuClose(); dispatch(setSelectedPipe(role)); console.log("selected role", role); }}
                    selectedPipe={selectedPipe}
                    roles={roles}
                    key={"category"}
                />


            </Menu >
        </>
    );
}