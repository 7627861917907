import React, { useState } from "react";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Box, Button, Typography } from "@mui/material";

export interface User {
    uuid: string,
    email: string,
    last_login: string,
}

interface Props {
    // open: boolean,
    users: User[] | null,
}

export const ListUsers: React.FC<Props> = (props) => {
    const columns: GridColDef[] = [
        { field: 'email', headerName: 'Email', width: 200, description: "User's email" },
        { field: 'isActivated', headerName: 'Aktivoitu', type: "Boolean", width: 150, description: "True / False has user activated their account" },
      ];
    const rows: GridRowsProp = [
        ...props.users ? props.users.map((user) => ({
            id: user.uuid, 
            email: user.email, 
            isActivated: (user.last_login ? true : false)
            })) 
        : []
    ]

    return (
        <DataGrid columns={columns} rows={rows}/>
    );
}